import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Tiles = ({ tileItems }) => (
  <div className='columns is-multiline is-centered'>
    {tileItems.map(item => (
      <div key={item.title} className='nav-tile-set'>
        <div 
          className="column has-text-centered" 
          style={{
              justifyContent: 'center',
              display: 'block',
          }}
        >
        <Link to={item.slug}>
        <div
          className="image is-square margin-top-0 nav-tile"
          style={{
            display: 'flex',
            paddingTop: 0,
            width: '100%',
            height: '180px',
            justifyContent: 'center',
            alignItems: 'flex-end',
            backgroundSize: 'cover',
            borderRadius: '15px',
            backgroundImage: `url(${
              !!item.image.childImageSharp
                ? item.image.childImageSharp.gatsbyImageData
                : item.image
              })`,
          }}
        >
          <h2
            className="has-text-weight-bold is-size-4 tile-label"
            style={{
              backgroundColor: '#009fb2',
              color: 'white',
              padding: '1rem',
              borderRadius: '15px',
            }}
          >
            {item.title}
          </h2>
        </div>
        </Link>
        <p className="nav-tile-blurb">{item.description}</p>
      </div>
      
  </div>
))}
</div>
)

Tiles.propTypes = {
  tileItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default Tiles
